import { useNavigate } from "react-router-dom";
import { checkRegister, useInverseCheckAuth } from "../Services/auth.service";
import { Button, Card, Col, Form, FormProps, Input, message, Row } from "antd";
import { NoticeType } from "antd/es/message/interface";
import { LoginOutlined } from '@ant-design/icons';

export default function Signup() {
  useInverseCheckAuth();

  let navigate = useNavigate();
  
  const [messageApi, contextHolder] = message.useMessage();

  type FieldType = {
    email: string;
    name: string;
    password: string;
    password2: string;
  };

  const openNotification = (type: NoticeType, body: string) => {
    console.log(body)
    messageApi.open({
      type: type,
      content: body,
      duration: 7,
    });
  };

  const onFinish: FormProps<FieldType>['onFinish'] = async (data) => {
    checkRegister(data.email, data.name, data.password).then(response => {
      if (response == null) {
        openNotification('warning', "There was an error logging in, please try again");
        return;
      }
      
      if (!response.data.message) {
        openNotification('success', "Registration Complete. Pending activation by an administrator");

        setTimeout(() => {
          navigate("/login");
        }, 4000);
      } else {
        openNotification('error', response.data.message);
      }
    }).catch(function (error) {
      openNotification('error', error.response.data.message);
    });
    
  };

  const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
    {contextHolder}
      
      <Row className="container" justify="center">
        <Col xs={24} sm={24} md={12} lg={8} xl={6} >
          <Card title="Account Registration">
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 24 }}
              style={{ maxWidth: 600 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              layout="vertical"
            >

              <Form.Item<FieldType>
                label="Full Name"
                name="name"
                rules={[{ required: true, message: 'Please input your full name' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item<FieldType>
                label="Email"
                name="email"
                rules={
                  [
                    { 
                      required: true, 
                      message: 'Please input your email' 
                    },
                    {
                      type: 'email',
                      message: 'The input is not valid E-mail!',
                    }
                  ]
                }
              >
                <Input />
              </Form.Item>

              <Form.Item<FieldType>
                label="Password"
                name="password"
                rules={[{ required: true, message: 'Please input your password' }]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item>
                <Button type="primary" icon={<LoginOutlined />} htmlType="submit" className="ant-btn-primary" block>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};