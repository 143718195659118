import { LineChart } from "@mui/x-charts";
import { MinusCircleFilled } from '@ant-design/icons';
import { Col, Divider, Popconfirm, Row, Spin, Table, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { getKeywords } from "../../Services/domains.service";
import KeywordModal from '../../Components/Modals/KeywordModal';
import LocationModal from "../Modals/LocationModal";
import { openNotification } from "../utils/notification.util";
import { removeKeyword, removeLocation } from "../../Services/keyword.service";
import StringUtils from "../../Utils/string.util";
const { Title } = Typography;

interface UseKeywordProps {
  domain: any;
}

export default function KeywordData({ domain }: UseKeywordProps) {

  //const [ chartsize, setChartSize ] = useState(50);
  const [ keywords, setKeywords ] = useState<any[]>([]);
  const [ locations, setLocations ] = useState<any[]>([]);
  const [ kwlogs, setKWLogs ] = useState<any[]>([]);
  const [ running, setRunning ] = useState<boolean>(false);
  const [ tableData, setTableData ] = useState<{
    key: string;
    keyword: string;
    rank: any;
    table: JSX.Element;
  }[]>([]);

  useEffect(() => {
    if (running) return;

    setRunning(true);
    calculateKeywords();
  }, []);

  const columns = [
    { 
      title: 'Keyword', 
      dataIndex: 'keyword', 
      key: 'keyword',
    },
    { 
      title: 'Rank', 
      dataIndex: 'rank', 
      key: 'rank',
      align: 'center' as const,
      render(text: any, record: any) {
        return {
        	props: {
            style: { 
              background: record.color,
              fontWeight: 600,
              fontSize: "18px"
             },
          },
        	children: <div>{text}</div>,
        };
      },
    },
  ];

  var charts = new Map<string, any>();

  async function recalculateKeywords(keywords: string[]) {
    keywords.forEach((keyword) => {
      const newData = {
        key: keyword,
        keyword: keyword,
        rank: <Spin />,
        table: <div><p>Data is loading...</p></div>
      };
      setTableData([...tableData, newData]);
    })
    
    getNewCalculated();
  }

  async function recalculateLocations(locs: string[]) {
    locs.forEach((location) => {
      const newData = {
        "id": locations.length + 1,
        "domain_id": domain.id,
        "location": location,
        "created_at": new Date().toLocaleDateString()
      };
      setLocations([...locations, newData]);
    });
    
    getNewCalculated();
  }

  function getNewCalculated() {
    //console.log("Checking for new keywords");
    setTimeout(() => {
      getKeywords(domain.id).then(
        response => {
          if (response == null) {
            openNotification('warning', "There was an error, please reload the page");
            return;
          }
    
          if (kwlogs !== response.data['logs'] || keywords.length !== response.data['keywords'].length || locations.length !== response.data['locations'].length) {
            console.log("Logs updated, logging new keyword data");
            setKeywords(response.data['keywords']);
            setLocations(response.data['locations']);
            setKWLogs(response.data['logs']);

            calculateKeywordCharts(response.data['keywords'], response.data['locations'], response.data['logs']);
            setRunning(false);
          }

          getNewCalculated();
        },
        error => {
          console.log(error);
        }
      );
    }, 2000);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function calculateKeywords() {
    // Get keywords and locations
    getKeywords(domain.id).then(
      response => {
        if (response == null) {
          openNotification('warning', "There was an error, please reload the page");
          return;
        }
  
        setKeywords(response.data['keywords']);
        setLocations(response.data['locations']);
        setKWLogs(response.data['logs']);

        calculateKeywordCharts(response.data['keywords'], response.data['locations'], response.data['logs']);
        setRunning(false);
      },
      error => {
        console.log(error);
      }
    );
  }

  async function confirmDeleteLocation(location: string) {
    setLocations(locations.filter(loc => loc.location !== location));
    await removeLocation(domain.id, location);
    calculateKeywords();
  }

  async function confirmDelete(keyword: string) {
    setKeywords(keywords.filter(kw => kw.keyword !== keyword));
    await removeKeyword(domain.id, keyword);
    calculateKeywords();
  }

  function dataTrim(data: any[], name: string) { 
    return data; 
  }

  async function calculateKeywordCharts(keywords: { keyword: string; }[], locations: { location: string; }[], logs: any[]) {

    let dates: string[] = [];
    logs.forEach(log => {
      const json = StringUtils.parseEscapedJSON(log.logs);

      console.log(json);

      json.forEach((item: any) => {
        if (!dates.includes(item.date.split('T')[0])) {
          dates.push(item.date.split('T')[0]);
        }
      });
    });
    dates.sort((a: string, b: string) => {
      return moment(a).diff(moment(b));
    });

    dates = dataTrim(dates, "dates");

    keywords.forEach((keyword: { keyword: string; }) => {
      const locData = logs.filter(log => {
        return log.keyword === keyword.keyword;
      }).map(item => {
        const json = StringUtils.parseEscapedJSON(item.logs);
        let data: any = [];
        let previousRank: null = null;
        dates.forEach(date => {
          const log = json.filter((log: { date: string; }) => {
            return log.date.split('T')[0] === date;
          });

          if (log.length > 0) {
            previousRank = log[0].rank
            data.push(previousRank);
          } else {
            data.push(previousRank);
          }
        });
        
        return {
          dates: dates,
          data: {
            curve: "linear",
            data: dataTrim(data, "data"),
            label: item.location,
            showMark: false,
          }
        }
      });

      

      charts.set(keyword.keyword, locData);
    });

    const renderColor = (rank: number) => {
      const opacity = "66";
      var color = 'transparent';
      if (rank <= 10) {
        color = '#04ff00';
      } else if (rank <= 30) {
        color = '#ccff00';
      } else if (rank <= 99) {
        color = '#ff8400';
      } else {
        color = '#ff0000';
      }

      return `${color}${opacity}`;
    }
    

    const tableDataNew = await keywords.map((keyword: {keyword: string;}) => {
      const locData = charts.get(keyword.keyword);
  
      var high = 0;
      var low = 1000;
  
      locData.forEach(async (location: { data: any; location: any; }) => {
        if (location.data !== undefined) {
          const logs = location.data.data;

          const tempLog = logs.filter((item: string) => item !== null);
          const lastLog = tempLog[tempLog.length - 1];

          if (lastLog < low) low = lastLog;

          logs.forEach((log: number | null) => {
            if (log != null) {
              if (log > high) high = log;
              //if (log < low) low = log;
            }
          });
        }
      });

      
  
      return {
        key: keyword.keyword,
        keyword: keyword.keyword,
        rank: low === 1000 ? <Spin size="default"></Spin> : low,
        color: renderColor(low),
        table: <div>
          <LineChart
            series={
              locData.map((location: any) => {
                return location.data;
              })
            }
            xAxis={
              [
                { 
                  scaleType: 'point',
                  data: dates, 
                  reverse: false,
                  valueFormatter: (value, context) => {
                    return moment(value).format('MMMM DD, YYYY hh:mm A');
                  } 
                }
              ]
            }
            yAxis={[
              { id: 'leftAxis', reverse: true, min: 0, max: high + 5 },
            ]}
            height={250}
            grid={{ vertical: false, horizontal: true }}
            skipAnimation={true}
            slotProps={{ legend: { hidden: true } }}
          />
        </div>
      }
  
    });

    setTableData(tableDataNew);
  }

  return (
    <>
      <Row justify="center" gutter={[30, 30]}>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Title level={3}>Keywords <KeywordModal domain={domain} text={"Add Keywords"} locations={locations} onComplete={async (keywords: string[]) => recalculateKeywords(keywords)} /></Title>
          <Divider />
          {
            keywords.map((kw: {keyword: string}) => (
              <div className="keyword-item">
                {kw.keyword} 
                <Popconfirm
                  title="Delete Keyphrase"
                  description={`Are you sure you want to delete the keyphrase '${kw.keyword}'?`}
                  onConfirm={(e) => {
                    confirmDelete(kw.keyword);
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <MinusCircleFilled className="delete-item" />
                </Popconfirm>
              </div>
            ))
          }
          <div style={{height:"25px"}}></div>
          <Title level={3}>Locations <LocationModal domain={domain} text={"Add Locations"} keywords={keywords} onComplete={async (locations: string[]) => recalculateLocations(locations)} /></Title>
          <Divider />
          {
            locations.map((loc: {location: string}) => (
              <div className="keyword-item">
                {loc.location}
                <Popconfirm
                  title="Delete Location"
                  description={`Are you sure you want to delete the tracked location '${loc.location}'?`}
                  onConfirm={(e) => {
                    confirmDeleteLocation(loc.location);
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <MinusCircleFilled className="delete-item" />
                </Popconfirm>
              </div>
            ))
          }
        </Col>
        <Col xs={24} sm={24} md={12} lg={18} xl={18}>
          {keywords.length === 0 && !running ?  
          <h3>No Keyword Data</h3>
          :
          tableData.length === 0 ? 
            <Spin tip="Loading" size="large">
              <div className="content" style={{height:"100px"}} />
            </Spin>
            : 
            <>
              <Table
                  className="alternating-colors"
                  columns={columns}
                  expandable={{
                    expandedRowRender: (record) => <p style={{ margin: 0 }}>{record.table}</p>,
                    rowExpandable: (record) => record.rank <= 1000,
                  }}
                  dataSource={tableData}
                  pagination={false}
                />
            </>
          }
        </Col>
      </Row>
    </>
  );
}