import { Button, Checkbox, CheckboxProps, Form, FormProps, Input, Modal } from "antd";
import { useState } from "react";
import { PlusCircleOutlined } from '@ant-design/icons';
import { addDomain } from "../../Services/domains.service";
import { useNavigate } from "react-router";
import { openNotification } from "../utils/notification.util";

interface UseModalProps {
  text: string;
  className: string;
}

export default function DomainModal({ text, className }: UseModalProps) {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checked, setChecked] = useState(true);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  type FieldType = {
    name?: string;
    url?: string;
    prospect?: boolean;
  };
  
  const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
    if (values.url !== null && values.name !== null) {
      const response = await addDomain(values.url!, values.name!, checked ? 2 : 1);
      if (response == null) {
        openNotification('warning', "There was an error adding the domain, please try again");
        return;
      }

      if (response.status === 200) {
        const data = response.data;
        console.log(data);
        navigate(`/domains/${data.domain.id}`);
      }
    }
  };
  
  const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onChange: CheckboxProps['onChange'] = (e) => {
    setChecked(e.target.checked);
  };
  
  return (
    <>
      <Button type="primary" icon={<PlusCircleOutlined />} className={className} onClick={showModal} block>
        {text}
      </Button>
      <Modal title={text} open={isModalOpen} onCancel={handleCancel} footer={null}>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          id="domain-form"
        >
          <Form.Item<FieldType> label="Company Name" name="name" rules={[{ required: true, message: 'Please input the name of the website' }]} >
            <Input />
          </Form.Item>

          <Form.Item<FieldType> label="URL" name="url" rules={[{ required: true, message: 'Please enter the url for the website' }]} >
            <Input />
          </Form.Item>

          <Form.Item<FieldType>
            label="Prospective Client"
            name="prospect"
            >
            <Checkbox name="prospect" checked={ checked } onChange={onChange}></Checkbox>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}