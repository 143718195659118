import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Dashboard from './Pages/Dashboard';
import Domain from './Pages/Domain';
import AppLayout from './AppLayout';
import AlertDashboard from './Pages/Alerts';
import Login from './Pages/Login';
import './Components/Styling/App.scss';
import { ConfigProvider, theme } from 'antd';
import Signup from './Pages/Signup';
import ToolDashboard from './Pages/Tools';
import ROI from './Pages/tools/ROI';

const darkTheme = {
  algorithm: theme.darkAlgorithm, 
  token: {
    "colorBgBase": "#1d1f23",
    "fontSize": 16,
    "colorPrimary": "#e66d0f",
    "colorInfo": "#e66d0f"
  }, 
};

const App = () => (
  <ConfigProvider theme={darkTheme}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AppLayout />}>
          <Route index={true} element={<Dashboard />} />
          <Route path="domains">
            <Route index={true} element={<Dashboard />} />
            <Route path=":domainId" element={<Domain />} />
          </Route>
          <Route path="alerts" element={<AlertDashboard />} />
          <Route path="tools">
            <Route index={true} element={<ToolDashboard />} />
            <Route path="roi" element={<ROI />} />
          </Route>
        </Route>

        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Signup />} />
        
      </Routes>
    </BrowserRouter>
  </ConfigProvider>
);

export default App;
