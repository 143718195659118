export const priceFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
});

export const sanitizeFloat = (input: string): number => {
  const cleanedValue = parseFloat(sanitizeFloatInput(input));
  return cleanedValue || 0;
};

export const sanitizeFloatInput = (input: string): string =>
  input.replaceAll(/[^0-9.]/g, "");

export const sanitizeDecimal = (input: string): number => {
  const cleanedValue = parseInt(sanitizeDecimalInput(input));
  return cleanedValue || 0;
};

export const sanitizeDecimalInput = (input: string): string =>
  input.replaceAll(/[^0-9]/g, "");

export const roundToHundreds = (input: number): number =>
  Math.round(input / 100) * 100;