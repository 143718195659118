/* eslint-disable jsx-a11y/anchor-is-valid */

import { Button, Col, Popconfirm, Row, Spin, Table } from 'antd';
import { useEffect, useState } from 'react';
import { dismissAlert, getAlerts } from '../Services/alert.service';
import { useNavigate } from 'react-router-dom';
import Moment from 'moment';
import { openNotification } from '../Components/utils/notification.util';

const AlertDashboard = () => {
  const navigate = useNavigate();

  const [ alerts, setAlerts ] = useState();

  useEffect(() => {
    loadAlerts();
  }, []);

  const loadAlerts = () => {
    getAlerts().then(
      response => {
        if (response == null) {
          openNotification('warning', "There was an error loading alerts, please reload the page");
          return;
        }

        var urldata = response.data['alerts'].map((alert: any) => {
          return {
            id: alert.id,
            name: alert.domain.name,
            domain_id: alert.domain.id,
            message: alert.message,
            created_at: Moment(alert.created_at).format('MMMM DD, YYYY hh:mm A')
          }
        });
        setAlerts(urldata);
      },
      error => {
        console.log(error);
      }
    );
  }

  
  async function handleDelete(key: any, name: any): Promise<void> {
    const response = await dismissAlert(key);
    if (response == null) {
      openNotification('warning', "There was an error dismissing the alert, please try again");
      return;
    }

    if (response.data["message"] === "success") {
      openNotification('success', `Alert dismissed for ${name}`);
    } else {
      openNotification('error', `There was a problem dismissing the alert for ${name}`);
    }

    loadAlerts();
  }


  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
    },
    {
      title: 'Created',
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: '',
      dataIndex: 'actions',
      render: (_: any, record: { domain_id: any; id: any; name: any; }) =>
        (
          <div className='alert-actions'>
            <Button type="primary" onClick={() => navigate(`/domains/${record.domain_id}`)}>View Account</Button>
            <Popconfirm title="Are you sure you want to dismiss this alert?" onConfirm={() => handleDelete(record.id, record.name)} okText="Yes" cancelText="No">
              <Button danger>Dismiss Alert</Button>
            </Popconfirm>
          </div>
        ),
    },
  ];

  return (
    <>
      {alerts === undefined ? 
        <Spin tip="Loading" size="large">
          <div className="content" />
        </Spin>
        : 
        <Row justify="center">
          <Col xs={24} sm={24} md={18} lg={18} xl={18}>    
              <Table className='alert-table' rowClassName='alert-row' dataSource={alerts} columns={columns} />
          </Col>
        </Row>
      }
    </>
  );
};

export default AlertDashboard;