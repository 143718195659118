/* eslint-disable jsx-a11y/anchor-is-valid */

import { Alert, Button, Col, Input, Row, Spin, Table, Tabs, TabsProps } from 'antd';
import { useEffect, useState } from 'react';
import { getDeactivated, getDomains, getProspects } from '../Services/domains.service';
import { useNavigate } from 'react-router-dom';
import Moment from 'moment';
import DomainModal from '../Components/Modals/DomainModal';
import { getAlerts } from '../Services/alert.service';
import { openNotification } from '../Components/utils/notification.util';
import moment from 'moment';

const Dashboard = () => {
  const navigate = useNavigate();

  const DEFAULT_TABLE = [{
    id: 0,
    name: '',
    url: '',
    created_at: '',
  }]

  const [ urls, setUrls ] = useState();
  const [ filterUrls, setFilterUrls ] = useState<{ id: number; name: string; url: string; created_at: string; }[]>();
  
  const [ prospectUrls, setProspectUrls ] = useState();
  const [ filterProspectUrls, setFilterProspectUrls ] = useState<{ id: number; name: string; url: string; created_at: string; }[]>();

  const [ deactivatedUrls, setDeactivatedUrls ] = useState();
  const [ filterDeactivatedUrls, setFilterDeactivatedUrls ] = useState<{ id: number; name: string; url: string; created_at: string; }[]>();

  const [ alerts, setAlerts ] = useState([]);

  useEffect(() => {
    getDomains().then(
      response => {
        if (response == null) {
          openNotification('warning', "There was an error loading domains, please reload the page");
          return;
        }

        var urldata = response.data['domains'].map((url: any) => {
          return {
            id: url.id,
            name: url.name,
            url: url.url,
            created_at: Moment(url.created_at).format('MMMM DD, YYYY hh:mm A')
          }
        });
        setUrls(urldata.sort((a: any, b: any) => a.name.localeCompare(b.name)));
        setFilterUrls(urldata);
      },
      error => {
        console.log(error);
      }
    );
    getProspects().then(
      response => {
        if (response == null) {
          //openNotification('warning', "There was an error loading domains, please reload the page");
          return;
        }

        var urldata = response.data['domains'].map((url: any) => {
          return {
            id: url.id,
            name: url.name,
            url: url.url,
            created_at: Moment(url.created_at).format('MMMM DD, YYYY hh:mm A')
          }
        });
        setProspectUrls(urldata.sort((a: any, b: any) => a.name.localeCompare(b.name)));
        setFilterProspectUrls(urldata);
      },
      error => {
        console.log(error);
      }
    );
    getDeactivated().then(
      response => {
        if (response == null) {
          //openNotification('warning', "There was an error loading domains, please reload the page");
          return;
        }

        var urldata = response.data['domains'].map((url: any) => {
          return {
            id: url.id,
            name: url.name,
            url: url.url,
            created_at: Moment(url.created_at).format('MMMM DD, YYYY hh:mm A')
          }
        });
        setDeactivatedUrls(urldata.sort((a: any, b: any) => a.name.localeCompare(b.name)));
        setFilterDeactivatedUrls(urldata);
      },
      error => {
        console.log(error);
      }
    );

    getAlerts().then(
      response => {
        if (response == null) {
          openNotification('warning', "There was an error loading alerts, please reload the page");
          return;
        }

        var urldata = response.data['alerts'].map((alert: any) => {
          return {
            id: alert.id,
            name: alert.domain.name,
            domain_id: alert.domain.id,
            message: alert.message,
            created_at: Moment(alert.created_at).format('MMMM DD, YYYY hh:mm A')
          }
        });
        setAlerts(urldata);
      },
      error => {
        console.log(error);
      }
    );
  }, []);

  let columns = [
    /*
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    */
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 500,
      sorter: (a: { name: string; }, b: { name: any; }) => a.name.localeCompare(b.name),
    },
    {
      title: 'URL',
      dataIndex: 'url',
      key: 'url',
      width: 500,
      sorter: (a: { url: string; }, b: { url: any; }) => a.url.localeCompare(b.url),
    },
    {
      title: 'Created',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: (a: { created_at: string; }, b: { created_at: string; }) => moment(a.created_at).unix() - moment(b.created_at).unix(),
    },
  ];

  // Table Filtering
  const onTableSearch = (e: { target: { value: any; }; }) => {
    onActiveSearch(e);
    onProspectSearch(e);
    onDeactivatedSearch(e);
  };
  const onActiveSearch = (e: { target: { value: any; }; }) => {
    const { value } = e.target;
    var baseData = DEFAULT_TABLE;

    if (urls !== undefined) {
      baseData = urls;
    }

    let filterTable = baseData.filter((o) => { 
      return o.url.toLowerCase().includes(value.toLowerCase()) || o.name.toLowerCase().includes(value.toLowerCase());
    });

    setFilterUrls(filterTable);
  };
  const onProspectSearch = (e: { target: { value: any; }; }) => {
    const { value } = e.target;
    var baseData = DEFAULT_TABLE;

    if (prospectUrls !== undefined) {
      baseData = prospectUrls;
    }

    let filterTable = baseData.filter((o) => { 
      return o.url.toLowerCase().includes(value.toLowerCase()) || o.name.toLowerCase().includes(value.toLowerCase());
    });

    setFilterProspectUrls(filterTable);
  };
  const onDeactivatedSearch = (e: { target: { value: any; }; }) => {
    const { value } = e.target;
    var baseData = DEFAULT_TABLE;

    if (deactivatedUrls !== undefined) {
      baseData = deactivatedUrls;
    }

    let filterTable = baseData.filter((o) => { 
      return o.url.toLowerCase().includes(value.toLowerCase()) || o.name.toLowerCase().includes(value.toLowerCase());
    });

    setFilterDeactivatedUrls(filterTable);
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Active',
      children: 
      <Table 
        showSorterTooltip={false}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              navigate(`/domains/${record.id}`);
            },
          };
        }}
        className='domain-table alternating-colors' 
        rowClassName='domain-row' 
        dataSource={filterUrls === DEFAULT_TABLE ? urls : filterUrls} 
        columns={columns} 
        pagination={{ position: ["bottomCenter", "bottomCenter"] }} 
      />,
    },
    {
      key: '2',
      label: 'Prospective Clients',
      children: 
      <Table 
        showSorterTooltip={false}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              navigate(`/domains/${record.id}`);
            },
          };
        }}
        className='domain-table alternating-colors' 
        rowClassName='domain-row' 
        dataSource={filterProspectUrls === DEFAULT_TABLE ? urls : filterProspectUrls} 
        columns={columns} 
        pagination={{ position: ["bottomCenter", "bottomCenter"] }} 
      />,
    },
    {
      key: '3',
      label: 'Cancelled',
      children: 
      <Table 
        showSorterTooltip={false}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              navigate(`/domains/${record.id}`);
            },
          };
        }}
        className='domain-table alternating-colors' 
        rowClassName='domain-row' 
        dataSource={filterDeactivatedUrls === DEFAULT_TABLE ? urls : filterDeactivatedUrls} 
        columns={columns} 
        pagination={{ position: ["bottomCenter", "bottomCenter"] }} 
      />,
    },
  ];

  const onChange = (key: string) => {
    const tabitem = items.filter(o => {
      return o.key === key
    })[0];

    console.log(tabitem.label);
  };


  return (
    <>
      {urls === undefined || alerts === undefined ? 
        <Spin tip="Loading" size="large">
          <div className="content" />
        </Spin>
        : 
        <Row justify="center">
          <Col xs={24} sm={24} md={22} lg={18} xl={18}>
              <Row gutter={10} align="bottom">
                <Col xs={24} sm={24} md={12} lg={10} xl={10}>
                  {alerts.length > 0 ? <Alert showIcon message={`${alerts.length} Active Alerts - Click details to view alerts`} action={
                    <Button danger onClick={() => {
                      navigate(`/alerts`);
                    }}>
                      Details
                    </Button>
                  } type="error" /> : ""}
                </Col>
                <Col xs={0} sm={0} md={0} lg={6} xl={8}></Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                  <DomainModal text="Add Domain" className="button-primary" />
                  <Input
                    placeholder="Search"
                    onChange={onTableSearch}
                    allowClear
                  />
                </Col>
              </Row>
              
              <Tabs
                onChange={onChange}
                type="card"
                className='domain-tabs'
                items={items}
                />
          </Col>
        </Row>
      }
    </>
  );
};

export default Dashboard;