import { getEndpoint } from '../Config';
import { handleAPIGet } from './handler.service';

const API_URL = getEndpoint("alerts");


export const getAlerts = () =>  {
  return handleAPIGet(API_URL + 'getAlerts');
}

export const dismissAlert = (alert_id: number) => {
  return handleAPIGet(API_URL + 'remove?id=' + alert_id, true);
}