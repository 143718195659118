import { Avatar, Button, Card, Col, Popconfirm, Row, Spin, Tabs, TabsProps} from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { getDomain, getDomainInfo, getMeta, removeDomain, updateStatus } from '../Services/domains.service';
import { useNavigate, useParams } from 'react-router-dom';
import Meta from 'antd/es/card/Meta';
import KeywordData from '../Components/KeywordData/KeywordData';
import { openNotification } from '../Components/utils/notification.util';
import { getRank } from '../Services/auth.service';


const Domain = () => {
  const {domainId} = useParams();
  const navigate = useNavigate();

  const [ domain, setDomain ] = useState();
  const [ meta, setMeta ] = useState({ image:"", title:"", description:"", url:"", icon:"", robots:"" });
  const [ rank, setRank ] = useState<number>(1);
  //const [ domaininfo, setDomainInfo ] = useState<[]>([]);

  useEffect(() => {
    if (domainId === undefined) navigate("/");

    getInfo();
  }, []);

  const getInfo = () => {
    if (domain === undefined) {
      getDomain(domainId!).then(
        response => {
          if (response == null) {
            openNotification('warning', "There was an error loading domains, please reload the page");
            return;
          }
  
          setDomain(response.data['domain']);

          // Get domain metadata
          getMeta(response.data['domain']['url']).then(
            response => {
              if (response == null) {
                openNotification('warning', "There was an error loading metadata, please reload the page");
              } else {
                setMeta(response.data['data']);
              }
            },
            error => {
              console.log(error);
            }
          );

          
          // Get performance reports
          getDomainInfo(response.data['domain']['url']).then(
            response => {
              if (response == null) {
                openNotification('warning', "There was an error loading domain info, please reload the page");
              } else {
                //setDomainInfo(response.data);
              }
            },
            error => {
              console.log(error);
            }
          );
        },
        error => {
          console.log(error);
        }
      );

      // Get User Rank
      getRank().then(
        response => {
          if (response == null) {
            //openNotification('warning', "There was an error loading metadata, please reload the page");
          } else {
            setRank(response.data['rank']);
          }
        },
        error => {
          console.log(error);
        }
      );
    }
  }
  
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Ranking',
      children: <KeywordData domain={domain} />,
    },
    /*
    {
      key: '2',
      label: 'Performance',
      children: <PerformanceData domain={domain} />,
    },
    {
      key: '3',
      label: 'Info',
      children: <DomainInfo data={domaininfo} />,
    },
    */
  ];

  const onChange = (key: string) => {
    const tabitem = items.filter(o => {
      return o.key === key
    })[0];

    console.log(tabitem.label);
  };

  const openLink = () => {
    window.open(`https://${domain!['url']}`, '_blank');
  };

  async function confirmDelete(domain_id: number) {
    await removeDomain(domain_id);
    navigate("/");
  }

  async function confirmConvert(domain_id: number, status: number) {
    await updateStatus(domain_id, status);
    window.location.reload();
  }


  return (
    <>
      {meta === undefined || domain === undefined ? 
      <Spin tip="Loading" size="large">
        <div className="content" />
      </Spin>
      : 
      <Row justify="center" gutter={[30, 30]}>
        <Col xs={24} sm={24} md={20} lg={8} xl={5}>
          <Card
            cover={
              <img
                alt="logo"
                className='domain-logo'
                src={meta!['image']}
              />
            }
            title={
              domain!['name']
            }
          >
            <Meta
              avatar={<Avatar src={meta!['icon']} />}
              title={
              <>
              {domain!['url']}
              <LinkOutlined onClick={openLink} style={{ fontSize: '20px', color: '#e66d0f', marginLeft: '10px' }} />
              </>}
              description={meta!['description']}
            />
            <Row className='domain-actions' justify="center">

              <Col span={24}>
              {
                rank > 1 ? 
                  domain!['status'] >= 1 ? 
                  <Popconfirm
                    title="Deactivate Domain"
                    description={`Are you sure you want to deactivate this domain '${domain!['url']}'?`}
                    onConfirm={(e) => {
                      confirmDelete(Number(domainId));
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button danger>Deactivate Domain</Button>
                  </Popconfirm>
                : 
                  <Popconfirm
                    title="Activate Domain"
                    description={`Are you sure you want to activate this domain '${domain!['url']}'?`}
                    onConfirm={(e) => {
                      confirmConvert(Number(domainId), 1);
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button className='success'>Activate Domain</Button>
                  </Popconfirm>
                :
                  ""
                }
              </Col>

              <Col span={24}>
                {
                domain!['status'] === 2 ? 
                  <>
                    
                    <Popconfirm
                      title="Convert To Client"
                      description={`Are you sure you want to convert this domain to a client?`}
                      onConfirm={(e) => {
                        confirmConvert(Number(domainId), 1);
                      }}
                      okText="Yes"
                      cancelText="No"
                      >
                      <Button type="primary">Convert to Client</Button>
                    </Popconfirm>
                  </>
                : 
                  <Popconfirm
                    title="Convert To Prospective Client"
                    description={`Are you sure you want to convert this domain to a prospective client?`}
                    onConfirm={(e) => {
                      confirmConvert(Number(domainId), 2);
                    }}
                    okText="Yes"
                    cancelText="No"
                    >
                    <Button type="primary">Convert to Prospective Client</Button>
                  </Popconfirm>
              }
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={20} lg={16} xl={18}>
          <Card>
            <Tabs
              onChange={onChange}
              type="card"
              items={items}
            />
          </Card>
        </Col>
      </Row>
      }
    </>
  );
};

export default Domain;