import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getEndpoint } from '../Config';
import { handleAPIGet, handleAPIPost } from './handler.service';

const API_URL = getEndpoint("auth");

export const checkLogin = (email: string, password: string) =>  {
  return handleAPIPost(API_URL + 'login', false,
    {
      email: email,
      password: password,
    }
  );
}

export const checkRegister = (email: string, username: string, password: string) =>  {
  return handleAPIPost(API_URL + 'register', false,
    {
      name: username,
      email: email,
      password: password,
    }
  );
}

export const checkToken = () => {
  return handleAPIGet(API_URL + 'check', true);
}

export const getRank = () => {
  return handleAPIGet(API_URL + 'rank', true);
}

export const logout = () => {
  localStorage.removeItem("auth");
  setTimeout(() => {
    window.location.href = '/login';
  }, 1000);
}

export function useCheckAuth(route = '/login') {
  const token = localStorage.getItem("auth");
  let navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate(route);
    } else {
      checkToken();
    }
  }, [ token, route, navigate ]);
}

export function useInverseCheckAuth(route = '/') {
  const token = localStorage.getItem("auth");
  let navigate = useNavigate();

  useEffect(() => {
    if (token)
      navigate(route);
    }, [ token, route, navigate ]
  );
}

export function getCurrentUser() {
  const userStr = localStorage.getItem("auth");
  if (userStr) return JSON.parse(userStr);
  return null;
}