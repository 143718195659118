export default class StringUtils {

  static parseEscapedJSON(escapedJson: string): any {
    const parsedJson = JSON.parse(escapedJson)
    const stringifiedParsedJson = parsedJson.toString();

    if (stringifiedParsedJson.includes('\"')) {
      return this.parseEscapedJSON(stringifiedParsedJson )
    }

    return parsedJson;
  }

}