import { getEndpoint } from '../Config';
import { handleAPIGet } from './handler.service';

const API_URL = getEndpoint("serp");

export const addSERP = (domain: any, keyword: string, location: string) =>  {
  return handleAPIGet(API_URL + 'checkrank?url=' + domain.url + '&location=' + location + '&keyword=' + keyword, false);
}

export const addKeyword = (domain: any, keyword: string) =>  {
  return handleAPIGet(API_URL + 'addkeyword?url=' + domain.url + '&keyword=' + keyword, true);
}

export const addLocation = (domain: any, location: string) =>  {
  return handleAPIGet(API_URL + 'addlocation?url=' + domain.url + '&location=' + location, true);
}

export const removeKeyword = (domain_id: number, keyword: string) =>  {
  return handleAPIGet(API_URL + 'removekeyword?domain=' + domain_id + '&keyword=' + keyword, true);
}

export const removeLocation = (domain_id: number, location: string) =>  {
  return handleAPIGet(API_URL + 'removelocation?domain=' + domain_id + '&location=' + location, true);
}