import React, { useCallback, useState, useMemo, useEffect } from "react";
import { EditOutlined } from '@ant-design/icons';
import cx from "classnames";

import { default as rawIndustryData } from "./data/industries.json";
import defaultCompetitivenessData from "./data/competitiveness.json";
import populationData from "./data/population.json";
import aggressivenessData from "./data/aggressiveness.json";
import { Button, Card, Checkbox, Col, Form, Input, InputNumber, Modal, Row, Select } from "antd";
import { priceFormatter, roundToHundreds, sanitizeDecimal, sanitizeDecimalInput, sanitizeFloat, sanitizeFloatInput } from "../../Utils/number.util";

const industryData = {
  "Select Industry": "Level 1",
  ...rawIndustryData,
};

type IndustryName = keyof typeof industryData;
type AggressivenessName = keyof typeof aggressivenessData;
type PopulationName = keyof typeof populationData;
type CompetitivenessName = keyof typeof defaultCompetitivenessData;
type CompetitivenessData = typeof defaultCompetitivenessData["Level 1"];

const legacyCompetivenessData: Record<CompetitivenessName, CompetitivenessData> = Object.fromEntries(
  Object.entries(defaultCompetitivenessData).map((val) => [
    val[0],
    { ...val[1], serviceFee: val[1].serviceFee - 100 },
  ])
) as Record<CompetitivenessName, CompetitivenessData>;

type SelectOptionType<T> = {
  label: T;
  value: T;
};

const availableDiscountsRaw: Array<[string, boolean]> = [
  ["Default Discount", true],
  ["No Website", false],
  ["Appointment setting department not involved", false],
  ["Discounted web consultant", false],
  ["Other", false],
];

const availableDiscounts = availableDiscountsRaw.map((x) => x[0]);
const discountEnabledInitial = availableDiscountsRaw.map((x) => x[1]);

// This rounds to the nearest hundreds place. Because JS doesn't have a method
// to do this automatically, we can divide by 100, round, then multiply by 100.


const industryKeys = [
  "Select Industry",
  ...Object.keys(industryData)
    .filter((value) => value !== "Select Industry")
    .sort(),
] as IndustryName[];

const industryOptions = industryKeys.map(
  (item) => ({
    value: item,
    label: item,
  })
);

const aggressivenessKeys = Object.entries(aggressivenessData)
  .sort((a, b) => a[1] - b[1])
  .map((val) => val[0]) as AggressivenessName[];

const competitivenessKeys = Object.keys(
  defaultCompetitivenessData
).sort() as CompetitivenessName[];

// const aggressivenessOptions: SelectOptionType<AggressivenessName>[] =
//   aggressivenessKeys.map((item) => ({
//     value: item,
//     label: item,
//   }));

const populationKeys = Object.entries(populationData)
  .sort((a, b) => a[1] - b[1])
  .map((val) => val[0]) as PopulationName[];

const populationOptions: SelectOptionType<PopulationName>[] =
  populationKeys.map((item) => ({
    value: item,
    label: item,
  }));

type CampaignParams = {
  industryCompetitiveness: CompetitivenessName;
  aggressiveness: AggressivenessName;
  population: PopulationName;
  competitivenessData: typeof defaultCompetitivenessData;
};

type CampaignOutput = {
  monthlyServiceFee: number;
  monthlyMarketingBudget: number;
  totalPayment: number;
};

const calculateCampaign = ({
  industryCompetitiveness,
  aggressiveness,
  population,
  competitivenessData,
}: CampaignParams): CampaignOutput => {
  console.log("competitivenessData: " + JSON.stringify(competitivenessData));
  console.log("industryCompetitiveness: " + industryCompetitiveness);


  // Base fees - these will, you guessed it, form the base of our calculations.
  // We'll look up different factors to multiply these based on the industry,
  // aggressiveness, and population of the campaign area.

  // Use "Level 1" as our base fees
  const { serviceFee: baseCampaignServiceFee, omb: baseMarketingBudget } =
    competitivenessData["Level 1"];

  const baseCampaignFee = baseCampaignServiceFee + baseMarketingBudget;

  // Lookups based on the input data. These provide additional information for
  // our calculation, looked up from provided tables.

  const {
    serviceFee: competitivenessServiceFee,
    omb: competitivenessMarketingBudget,
  } = competitivenessData[industryCompetitiveness];
  const campaignAreaFactor = populationData[population];
  const aggressivenessFactor = aggressivenessData[aggressiveness];

  // Calculations

  const additionalCost =
    competitivenessMarketingBudget *
    (campaignAreaFactor * aggressivenessFactor - 1.0);

  const additionalServiceFee = additionalCost * 0.2;
  const additionalMarketingFee = additionalCost * 0.8;

  // Final outputs - these are what are actually displayed on the page.
  //
  // Most of these are temporary calculations. This really should be calculated
  // as the monthlyServiceFee + monthlyMarketingBudget, but for some reason it's
  // calculated backwards and really challenging to reverse it properly because
  // the calculations are black magic.
  const totalPayment =
    roundToHundreds(
      competitivenessServiceFee +
        competitivenessMarketingBudget +
        additionalServiceFee +
        additionalMarketingFee
    ) - 5.0;

  // This is copied directly from the original spreadsheet - what is this trying
  // to do?
  // /*
  const monthlyMarketingBudget =
    (totalPayment - baseCampaignFee) * 0.8 + baseMarketingBudget;

  const monthlyServiceFee = totalPayment - monthlyMarketingBudget;

  // */
  /*
  // This is v2 of the calculations - what they really *should* be, but can't
  // because of some oddities.
  const monthlyServiceFee = competitivenessServiceFee + additionalServiceFee;
  const monthlyMarketingBudget = totalPayment - monthlyServiceFee;
  // */

  return {
    monthlyServiceFee,
    monthlyMarketingBudget,
    totalPayment,
  };
};


const ROI = () => {

  const defaultSetupFee = 995;

  const [newCustomerRevenue, setNewCustomerRevenue] = useState("$0");
  const [newCustomers, setNewCustomers] = useState("0");
  const [industry, setIndustry] = useState(industryKeys[0]);
  const [population, setPopulation] = useState(populationKeys[0]);
  const [presetName, setPresetName] = useState<"default" | "legacy" | undefined>("default");
  
  const [baseSetup, setBaseSetup] = useState<number>(defaultSetupFee);

  // Setup Fee
  const [selectedDiscounts, setSelectedDiscounts] = useState(
    discountEnabledInitial
  );
  const numDiscounts = selectedDiscounts.reduce(
    (prev, cur) => (cur ? prev + 1 : prev),
    0
  );
  const baseSetupFee = baseSetup + 500 * availableDiscountsRaw.length;
  const setupFee = baseSetupFee - 500 * numDiscounts;

  // Service Fee
  const [competitivenessData, setCompetitivenessData] = useState<
    Record<CompetitivenessName, CompetitivenessData>
  >(defaultCompetitivenessData);

  useEffect(() => {
    if (presetName === "default") {
      setCompetitivenessData(defaultCompetitivenessData);
    } else if (presetName === "legacy") {
      setCompetitivenessData(legacyCompetivenessData);
    }
  }, [setCompetitivenessData, presetName]);


  const totalNewRevenue =
    sanitizeFloat(newCustomerRevenue) * sanitizeDecimal(newCustomers) * 12;

  const competitvenessEdit = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      prop: keyof CompetitivenessData,
      key: CompetitivenessName
    ) => {
      setCompetitivenessData({
        ...competitivenessData,
        [key]: {
          ...competitivenessData[key],
          [prop]: sanitizeDecimal(e.target.value),
        },
      });
      setPresetName(undefined);
    },
    [competitivenessData, setCompetitivenessData, setPresetName]
  );

  const industryCompetitiveness = industryData[industry] as CompetitivenessName;

  // Run the campaign calculations.
  const campaigns: Array<[AggressivenessName, CampaignOutput]> = useMemo(() => {
    const ret = aggressivenessKeys.map(
      (aggressiveness: AggressivenessName) =>
        [
          aggressiveness,
          calculateCampaign({
            industryCompetitiveness,
            aggressiveness,
            population,
            competitivenessData,
          }),
        ] as [AggressivenessName, CampaignOutput]
    );
    return ret;
  }, [industryCompetitiveness, population, competitivenessData]);

  // This is technically also calculated in "campaigns" above, but this keeps
  // the code way cleaner and maintainable.
  const entryLevelCampaign = useMemo(
    () =>
      calculateCampaign({
        industryCompetitiveness,
        aggressiveness: "Entry Level",
        population,
        competitivenessData,
      }),
    [industryCompetitiveness, population, competitivenessData]
  );

  const [isSetupFeeOpen, setIsSetupFeeOpen] = useState(false);
  const [isServiceFeeOpen, setIsServiceFeeOpen] = useState(false);

  const setupFeeModal = () => {
    return (
      <Modal className="calculator-modal" title={"Setup Fee Adjustments"} open={isSetupFeeOpen} onCancel={() => setIsSetupFeeOpen(false)} footer={null}>
        <>
          {availableDiscounts.map((val, idx) => (
            <div>
              <Checkbox
                onChange={() => {
                  const ret = [...selectedDiscounts];
                  ret[idx] = !ret[idx];
                  setSelectedDiscounts(ret);
                }}
                checked={selectedDiscounts[idx]}
                >
                {val}
              </Checkbox>
            </div>
          ))}
          <div className="setup-fee-input">
            <h4>Base Setup Fee</h4>
            <InputNumber<number>
                value={baseSetup}
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '') as unknown as number}
                onChange={(value) => setBaseSetup(value ?? defaultSetupFee)}
              />
          </div>
          <Button type="primary" 
            onClick={() => {
              setSelectedDiscounts(discountEnabledInitial); 
              setBaseSetup(defaultSetupFee);
            }}>Reset</Button>
        </>
      </Modal>
    )
  }

  const serviceFeeModal = () => {
    return (
      <Modal className="calculator-modal" title={"Service Fee Adjustments"} open={isServiceFeeOpen} onCancel={() => setIsServiceFeeOpen(false)} footer={null} width={600}>
        <>
          <table>
            <tbody>
              <tr>
                <th></th>
                <th>Service Fee</th>
                <th>Online Marketing Budget</th>
              </tr>
              {competitivenessKeys.map((val, idx) => (
                <tr key={val}>
                  <td align="left">{val}</td>
                  <td align="center">
                    <Input
                      type="text"
                      name={val + "-edit-service"}
                      onChange={(e) => competitvenessEdit(e, "serviceFee", val) }
                      value={competitivenessData[val].serviceFee}
                      className="basic-input"
                    />
                  </td>
                  <td align="center">
                    <Input
                      type="text"
                      name={val + "-edit-omb"}
                      onChange={(e) => competitvenessEdit(e, "omb", val)}
                      value={competitivenessData[val].omb}
                      className="basic-input"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <br/>
          <Button onClick={() => setCompetitivenessData(defaultCompetitivenessData)}>Reset</Button>
        </>
      </Modal>
    );
  }

  // Note: the ROI is currently calculated based on only the entry-level
  // campaign. This would be trivial to expand in the future if needed.
  let roi: number[] = [];

  for (let i = 1; i < 6; i++) {
    // This was originally manually computed and I noticed that the number of
    // customer groups is based on the triangular number sequence. That
    // corresponds with the numbers of customers we will have assuming all
    // customers are recurring.
    //
    // https://www.mathsisfun.com/algebra/triangular-numbers.html
    //
    // So, the first year, our multiplier will be 1, the second year it will be
    // 3 (1 from the first year, plus the recurring customers and new customers
    // the second year).
    const customerMultiplier = (i * (i + 1)) / 2;

    // The revenue for this year is new revenue per customer times the number of
    // active customers. The cost is the monthly campaign fee times 12 times the
    // current year, added to the setup fee. Multiplying by 100 gives us a
    // percentage.
    const revenue = totalNewRevenue * customerMultiplier;
    const costs = entryLevelCampaign.totalPayment * i * 12 + setupFee;

    roi.push(Math.round((100 * revenue) / costs));
  }

  return (
    <Row justify={"center"}>

      <Col xs={24} sm={24} md={18} lg={18} xl={18}>
        <Row className="calculator" gutter={[30, 30]}>
          
        {setupFeeModal()}
        {serviceFeeModal()}

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Card title="Industry & Campaign Area">
              <h4>Industry:</h4>
              <Select
                defaultValue={industryOptions[0]}
                onChange={(e: any) =>  setIndustry(e) }
                options={industryOptions}
                size={'large'}
                style={{ width: '100%' }}
              />
              <h4>Industry Competitiveness:</h4>
              <Input
                type="text"
                name="industryCompetitiveness"
                value={industryCompetitiveness}
                disabled={true}
                className="basic-input"
                size={'large'}
              />
              <h4>
                Population Size (
                <a href="https://sedac.ciesin.columbia.edu/mapping/popest/pes-v3/" target="_blank" rel="noreferrer">
                  sizing tool
                </a>
                ):
              </h4>
              <Select
                defaultValue={populationOptions[0]}
                onChange={(e: any) => setPopulation(e) }
                options={populationOptions}
                size={'large'}
                style={{ width: '100%' }}
              />
            </Card>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Card title="Campaign ROI">
              <h4>Annual new customer revenue:</h4>
              <Input
                type="text"
                name="newCustomerRevenue"
                onBlur={() => setNewCustomerRevenue(priceFormatter.format(sanitizeFloat(newCustomerRevenue))) }
                onFocus={() => {
                  const cleanedValue = sanitizeFloat(newCustomerRevenue);
                  setNewCustomerRevenue(cleanedValue ? cleanedValue.toString() : "");
                }}
                onChange={(e) => setNewCustomerRevenue(sanitizeFloatInput(e.target.value))}
                value={newCustomerRevenue}
                className="basic-input"
                size={'large'}
              />
              <h4>New customers/month:</h4>
              <Input
                type="text"
                name="newCustomers"
                value={newCustomers}
                onBlur={() => setNewCustomers(sanitizeDecimal(newCustomers).toString())}
                onFocus={(e) => {
                  const cleanedValue = sanitizeDecimal(newCustomers);
                  setNewCustomers(cleanedValue ? cleanedValue.toString() : "");
                }}
                onChange={(e) => setNewCustomers(sanitizeDecimalInput(e.target.value))}
                className="basic-input"
                size={'large'}
              />
              <h4>Total new revenue/year:</h4>
              <Input
                type="text"
                name="totalNewRevenue"
                value={priceFormatter.format(totalNewRevenue)}
                disabled={true}
                className="basic-input"
                size={'large'}
              />
            </Card>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Card title="Campaign Fee">
              <div className="center">
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Monthly Service Fee <EditOutlined onClick={() => setIsServiceFeeOpen(true)} className="edit-icon" style={{ display: 'none' }} /></th>
                      <th></th>
                      <th>Online Marketing Budget</th>
                      <th></th>
                      <th className="table-payment">Monthly Payment</th>
                      <th className="table-setup">Setup Fee <EditOutlined onClick={() => setIsSetupFeeOpen(true)} className="edit-icon" /></th>
                    </tr>
                  </thead>
                  <tbody>
                    {campaigns.map(([name, campaign]) => (
                      <tr key={name}>
                        <th align="right">{name}</th>
                        <td align="center">
                          {priceFormatter.format(campaign.monthlyServiceFee)}
                        </td>
                        <td>+</td>
                        <td align="center">
                          {priceFormatter.format(campaign.monthlyMarketingBudget)}
                        </td>
                        <td>=</td>
                        <td className="table-payment" align="center">
                          {priceFormatter.format(campaign.totalPayment)}
                        </td>
                        <td className="table-setup" align="center">
                          {priceFormatter.format(setupFee)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Card>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Card title="Return on Investment">
              <Row justify={"center"}>
                {
                  roi.map((val, idx) => (
                    <Col xs={8} sm={6} md={4} lg={4} xl={2} className="center">
                      <h4>Year {idx + 1}</h4>
                      {val}%
                    </Col>
                  ))
                }
              </Row>
            </Card>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{display:'none'}}>
            <Card title="Settings">
              <Button className={cx({ selected: presetName === "default" })} onClick={() => setPresetName("default")} >
                Current Preset
              </Button>

              <Button className={cx({ selected: presetName === "legacy" })} onClick={() => setPresetName("legacy")} >
                Legacy Preset
              </Button>
            </Card>
          </Col>
        
        </Row>
      </Col>
    </Row>
  );
};

export default ROI;