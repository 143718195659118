import { getEndpoint } from '../Config';
import { handleAPIGet, handleAPIPost } from './handler.service';

const API_URL = getEndpoint("domains");


export const getDomains = () =>  {
  return handleAPIGet(API_URL + 'retrieve');
}

export const getProspects = () =>  {
  return handleAPIGet(API_URL + 'getprospects', true);
}

export const getDeactivated = () =>  {
  return handleAPIGet(API_URL + 'getdeactivated', true);
}

export const getDomain = (id: string) =>  {
  return handleAPIGet(API_URL + 'getdomain?id=' + id);
}

export const getMeta = (url: string) =>  {
  return handleAPIGet(API_URL + 'getmeta?url=' + url);
}

export const getKeywords = (id: string) =>  {
  return handleAPIGet(API_URL + 'getkeywords?id=' + id);
}

export const getPerformanceReports = (id: string) =>  {
  return handleAPIGet(API_URL + 'getperformance?id=' + id);
}

export const getDomainInfo = (url: string) =>  {
  return handleAPIGet(API_URL + 'info?url=' + url);
}

export const addDomain = (url: string, name: string, status: number) =>  {
  return handleAPIPost(API_URL + 'adddomain', true,
    {
      url: url,
      name: name,
      status: status
    }
  );
}

export const removeDomain = (domain_id: number) =>  {
  return handleAPIPost(API_URL + 'removedomain', true,
    {
      id: domain_id
    }
  );
}

export const updateStatus = (domain_id: number, status: number) =>  {
  return handleAPIPost(API_URL + 'updatestatus', true,
    {
      id: domain_id,
      status: status,
    }
  );
}