import { useNavigate } from "react-router-dom";
import { checkLogin, useInverseCheckAuth } from "../Services/auth.service";
import { LoginOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, FormProps, Input, Row } from "antd";
import { openNotification } from "../Components/utils/notification.util";

export default function Login() {
  useInverseCheckAuth();

  let navigate = useNavigate();

  type FieldType = {
    email: string;
    password: string;
  };

  const onFinish: FormProps<FieldType>['onFinish'] = async (data) => {
    checkLogin(data.email, data.password).then(response => {
      if (response == null) {
        openNotification('warning', "There was an error logging in, please try again");
        return;
      }

      if (!response.data.message) {
        openNotification('success', "Welcome back!");
  
        localStorage.setItem("auth", JSON.stringify(response.data));
  
        setTimeout(() => {
          navigate("/");
        }, 2000);
      } else {
        openNotification('warning', response.data.message);
      }
    }).catch(function (error) {
      openNotification('error', JSON.stringify(error));
    });
    
  };

  const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  
  return (
    <>      
      <Row className="container" justify="center">
        <Col xs={24} sm={24} md={12} lg={8} xl={6} >
          <Card title="Account Login">
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 24 }}
              style={{ maxWidth: 600 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              layout="vertical"
            >
              <Form.Item<FieldType>
                label="Email"
                name="email"
                rules={[{ required: true, message: 'Please input your email' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item<FieldType>
                label="Password"
                name="password"
                rules={[{ required: true, message: 'Please input your password' }]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item>
                <Button type="primary" icon={<LoginOutlined />} htmlType="submit" className="ant-btn-primary" block>
                  Submit
                </Button>
              </Form.Item>
            </Form>

            <p style={{textAlign:"center"}}>Don't have an account? <a href="/register">Register for an account</a></p>
          </Card>
        </Col>
      </Row>
    </>
  );
};