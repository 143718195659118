import axios from "axios";
import { openNotification } from "../Components/utils/notification.util";
import { getCurrentUser, logout } from "./auth.service";

export const handleAPIGet = async (url: string, authenticate: boolean = false) =>  {
  return axios.get(
    url, 
    authenticate ? {
      headers: {
        'x-access-token': getCurrentUser().accessToken,
      }
    } : {}
  ).catch(err => {
    if (err.response.status === 401) {
      openNotification('error', err.response.data.message ?? err.message, true);
      logout();
    } else {
      openNotification('error', err.response.data.message ?? err.message, true);
    }
    console.log(err);
  }).then(response => {
    return response;
  });
}

export const handleAPIPost = async (url: string, authenticate: boolean = false, data?: any) =>  {
  return axios.post(
    url, 
    data, 
    authenticate ? {
      headers: {
        'x-access-token': getCurrentUser().accessToken,
      }
    } : {}
  ).catch(err => {
    if (err.response.status === 401) {
      openNotification('error', err.response.data.message ?? err.message, true);
      logout();
    } else {
      openNotification('error', err.response.data.message ?? err.message, true);
    }
    console.log(err);
  }).then(response => {
    return response;
  });
}