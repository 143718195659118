import { Card, Col, Divider, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { CalculatorOutlined } from '@ant-design/icons';

const ToolDashboard = () => {
  const navigate = useNavigate();

  const tools = [
    {
      id: 1,
      title: "ROI Calculator",
      link: "roi",
      description: "Campaign ROI calculator for industries and campaign areas",
      icon: <CalculatorOutlined />
    },
  ]

  return (
    <>
      <Row justify="center">
        <Col xs={24} sm={24} md={18} lg={18} xl={18}>    
          <Row className='tool-container'>
            {
              tools.map((item) => (
                <Col span={8}>
                  <Card className='tool-card' onClick={() => navigate(item.link)}>
                    <div className='tool-title'>{item.icon} {item.title}</div>
                    <Divider />
                    {item.description}
                  </Card>
                </Col>
              ))
            }
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ToolDashboard;