import { Button, Form, FormProps, Modal } from "antd";
import { useState } from "react";
import { PlusCircleOutlined } from '@ant-design/icons';
import { addKeyword } from "../../Services/keyword.service";
import TextArea from "antd/es/input/TextArea";

interface UseModalProps {
  domain: any;
  text: string;
  locations: any[];
  onComplete: (keyword: string[]) => {};
}

export default function KeywordModal({ domain, text, locations, onComplete }: UseModalProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  type FieldType = {
    keywords?: string;
  };
  
  const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
    if (values.keywords !== null) {
      const keywords = values.keywords!.split('\n');
      keywords.forEach((keyword) => {
        addKeyword(domain, keyword);
      })
      
      setIsModalOpen(false);
      onComplete(keywords);

      const resetForm = document.getElementById("keyword-form") as HTMLFormElement;
      resetForm.reset();
    }
  };
  
  const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  
  return (
    <>
      <PlusCircleOutlined onClick={showModal} style={{ fontSize: '20px', color: '#e66d0f' }} />
      <Modal title={text} open={isModalOpen} onCancel={handleCancel} footer={null}>
        <Form
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          id="keyword-form"
        >
          <Form.Item<FieldType> label="Keywords" name="keywords" rules={[{ required: true, message: 'Please input the focus keywords/keyphrases' }]} >
            <TextArea rows={8} />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Add Keywords
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}