import { Button, Form, FormProps, Modal } from "antd";
import { useState } from "react";
import { PlusCircleOutlined } from '@ant-design/icons';
import { addLocation } from "../../Services/keyword.service";
import TextArea from "antd/es/input/TextArea";

interface UseModalProps {
  domain: any;
  text: string;
  keywords: any[];
  onComplete: (locations: string[]) => {};
}

export default function LocationModal({ domain, text, keywords, onComplete }: UseModalProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  type FieldType = {
    locations?: string;
  };
  
  const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
    if (values.locations !== null) {
      const locations = values.locations!.split('\n');
      locations.forEach((location) => {
        addLocation(domain, location);
      });
      
      setIsModalOpen(false);
      onComplete(locations);

      const resetForm = document.getElementById("location-form") as HTMLFormElement;
      resetForm.reset();
    }
  };
  
  const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  
  return (
    <>
      <PlusCircleOutlined onClick={showModal} style={{ fontSize: '20px', color: '#e66d0f' }} />
      <Modal title={text} open={isModalOpen} onCancel={handleCancel} footer={null}>
        <p className="hint">Use the format: <br/><span>City, State</span> or <span>City, State, Country</span><br/><br/>Example:<br/> <span>Tacoma, WA, USA</span></p>
        <Form
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          id="location-form"
          layout="vertical"
        >
          <Form.Item<FieldType> label="Locations (One per line)" name="locations" rules={[{ required: true, message: 'Please input the location' }]} >
            <TextArea rows={8} />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Add Locations
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}