import { Layout, Menu } from 'antd';
import { useEffect } from 'react';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import { logout, useCheckAuth } from './Services/auth.service';

const { Content, Footer } = Layout; 

const pages = [
  {
    id: 1,
    title: "Domains",
    links: ["domains", ""],
    link: "/"
  },
  {
    id: 2,
    title: "Tools",
    links: ["tools"],
    link: "/tools"
  },
  {
    id: 3,
    title: "Alerts",
    links: ["alerts"],
    link: "/alerts"
  },
]

const AppLayout = () => {

  useCheckAuth();

  useEffect(() => {
    document.title = 'Ignite Local Panel';
  }, []);

  function getSelected() {

    return 1;
  }

  const { pathname } = useLocation();

  return (
    <Layout style={{minHeight: "100vh"}}>
        <Menu mode="horizontal" className='navmenu sticky' defaultSelectedKeys={[getSelected().toString()]}>
          {
            pages.map((item) => (
              <NavLink to={item.link} className={item.links.includes(pathname.split("/")[1]) ? "isActive" : ""} >
                {item.title}
              </NavLink>
            ))
          }
          
          <button className="logout-link" onClick={() => logout()}>Logout</button>
        </Menu>
      <Layout>
        <Content className='container'>
          <Outlet />
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          Ignite Local ©{new Date().getFullYear()} All rights reserved
        </Footer>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
