import Toast from "typescript-toastify";
import { ToastType } from "typescript-toastify/lib/type/type";

export var notifications: Toast[] = [];

export const openNotification = (type: ToastType, body: string, clear: boolean = false) => {
  if (clear) {
    for (const toast of notifications) {
      toast.remove();
    }
  }

  const toast = new Toast({
    position: "top-center",
    toastMsg: body,
    autoCloseTime: 7000,
    canClose: true,
    showProgress: true,
    pauseOnHover: true,
    pauseOnFocusLoss: true,
    type: type,
    theme: "dark"
  });
  notifications.push(toast);
};